import React from 'react'
import ContactFormSection from '../forms/ContactFormSection'
import { Link } from 'react-router-dom'

const ContactSection = () => {
    return (
        <div className="container">

            <div className="fz-inner-contact-details">
                <div className="fz-inner-contact-details__left">
                    <div className="fz-blog-details__comment-form">
                        <h4 className="fz-comment-form__title fz-inner-contact-details__title">Let us know more about your event.</h4>
                        <ContactFormSection />
                    </div>
                </div>

                <div className="fz-inner-contact-details__info-card">
                    <h3 className="fz-inner-contact-details__title">Get In Touch</h3>
                    <ul>
                        <li className="fz-single-contact-info">
                            <i className="fa-light fa-location-dot"></i>
                            <span className="contact-address">4500 Satellite Blvd Duluth, Georgia 30096</span>
                        </li>

                        <li className="fz-single-contact-info">
                            <i className="fa-light fa-phone"></i>
                            <div className="contact-numbers">
                                <span><a href="tel:(470) 658-6408">(470) 658-6408</a></span>

                            </div>
                        </li>

                        <li className="fz-single-contact-info">
                            <i className="fa-light fa-envelope"></i>
                            <div className="contact-numbers">
                                <span> <a href="mailto:privatefusionchef@gmail.com">privatefusionchef@gmail.com</a></span>

                            </div>
                        </li>



                        <li className="contact-socials">
                            <h6 className="contact-socials__title">Follow Us:</h6>
                            <ul>

                                <li><a target="_blank"
                                    rel="noopener noreferrer" href="https://www.instagram.com/privatefusionchef/"><i className="fa-brands fa-instagram"></i></a></li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ContactSection