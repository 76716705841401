import { useContext } from "react";
import MobileMenuSection from "../mobile-menu/MobileMenuSection";
import { Link, useNavigate } from "react-router-dom";
import { FarzaaContext } from "../../context/FarzaaContext";

const RightSideBar = ({ showMenu }) => {
  const { isSidebarOpen, handleSidebarClose } = useContext(FarzaaContext);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Function to close the modal and navigate
  const closeAndNavigate = (path) => {
    handleSidebarClose();
    navigate(path);
  };
  return (
    <div
      className={`fz-offcanvas-main-nav-wrapper ${isSidebarOpen ? "open" : ""}`}
    >
      <button className="fz-button-close" onClick={handleSidebarClose}>
        <i className="fa-thin fa-xmark"></i>
      </button>
      <div className="fz-offcanvas-main-nav-wrapper-sections">
        <div
          className={`fz-offcanvas-main-nav-section ${showMenu ? "show" : ""}`}
        >
          <div
            className={`mobile-menu-updated ${isSidebarOpen ? "mean-container" : ""
              }`}
          >
            <MobileMenuSection navigate={closeAndNavigate} />
          </div>
        </div>
        <div className="fz-offcanvas-main-sideinfo-section">
          <div className="fz-offcanvas-main-sideinfo">



            <div className="fz-offcanvas-main-contacts">
              <h4 className="fz-offcanvas-main-contacts-title mb-30">
                Get In Touch
              </h4>

              <ul className="fz-offcanvas-main-contacts-list">
                <li>
                  <i className="fa-thin fa-location-dot"></i>
                  <span className="fz-offcanvas-main-contact-text">
                    4500 Satellite Blvd Duluth, Georgia 30096
                  </span>
                </li>
                <li>
                  <i className="fa-thin fa-phone-flip"></i>
                  <span className="fz-offcanvas-main-contact-text">
                    <Link to="tel:(470) 658-6408">(470) 658-6408</Link>{" "}
                  </span>
                </li>

                <li>
                  <i className="fa-thin fa-envelope"></i>
                  <span className="fz-offcanvas-main-contact-text">
                    <Link to="mailto:privatefusionchef@gmail.com">privatefusionchef@gmail.com</Link>{" "}
                  </span>
                </li>

              </ul>
            </div>

            <div className="fz-offcanvas-main-socials mt-45">

              <Link
                to="https://www.instagram.com/privatefusionchef/"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fa-brands fa-instagram"></i>
                <i className="fa-brands fa-instagram"></i>
              </Link>

              


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
