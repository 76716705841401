import React from 'react'

const LocationSection = () => {
  return (
    <div className="fz-contact-location-map">
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3309.973071511834!2d-84.15991830720392!3d33.9418207039854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4353554aabc2c369%3A0x7ea99e241db871cd!2sPrivate%20Fusion%20Chef!5e0!3m2!1svi!2s!4v1734865294206!5m2!1svi!2s" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
  )
}

export default LocationSection